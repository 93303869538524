import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import { Card, Row, Col } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Collapsible from 'react-collapsible';
import { ListManager } from 'react-beautiful-dnd-grid';
import LoadingOverlay from 'react-loading-overlay';
import Switch from "react-switch";

const MorphingPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false)

    const [toggleMorphing, setToggleMorphing] = useState(false)
    const handleToggleMorphing = () => {
        setToggleMorphing(!toggleMorphing)
    }

    const [showToast, setShowToast] = useState(false);

    const [fullscreenImage, setFullscreenImage] = useState(null);

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    const [query_patient_image, setQuery_patient_image] = useState(null)
    const [template_patient_image, setTemplate_patient_image] = useState(null)
    const [morphed_image_helena_inpainting, setMorphed_image_helena_inpainting] = useState(null)
    const [morphed_image_helena_disentanglement, setMorphed_image_helena_disentanglement] = useState(null)
    const [morphed_image_nuno, setMorphed_image_nuno] = useState(null)

    let getImages = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            console.log(data)
            setQuery_patient_image(data['query_patient_image'])
            setTemplate_patient_image(data['template_patient_image'])
            setMorphed_image_helena_inpainting(data['inpainting_morphed_img'])
            setMorphed_image_helena_disentanglement(data['disentanglement_morphed_img'])
            setMorphed_image_nuno(data['nuno_morphed_img'])
        }
        else {
            console.log("ups")
        }
        setIsActive(false)
    }

    return (
        <Container style={{ minWidth: '100vw' }}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Morphing Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <ToastContainer className="mt-5" position="top-center">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='success'>
                    <Toast.Header>
                        <strong className="me-auto">Download Successful</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Go to the '/media/csvs' in the CINDERELLA NAS.
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {
                user !== null ?
                    user.is_staff ?
                        <Container>
                            <div className='addPatient-div'>
                                <Button variant="outline-primary" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-download" aria-hidden="true"></i> Download Morphing Data</Button>
                            </div>
                        </Container> :
                        null
                    :
                    null
            }

            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <Button variant="outline-primary" className="addPatient-button morphingButton" onClick={() => getImages()}><i className="fa fa-check" aria-hidden="true"></i> Fetch</Button>
            </div>
            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <Card className="text-center queryCardMorphing mx-4" style={{ width: '20vw' }}>
                    <Card.Header style={{ backgroundColor: "white" }}>Query Patient</Card.Header>
                    <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={query_patient_image === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + query_patient_image} width="250" height="250" />
                </Card>

                <Card className="text-center queryCardMorphing" style={{ width: '20vw' }}>
                    <Card.Header style={{ backgroundColor: "white" }}>Template Patient</Card.Header>
                    <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={query_patient_image === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + template_patient_image} width="250" height="250" />
                </Card>
            </div>

            <LoadingOverlay active={isActive} spinner>
                <Row className="responsiveRow">
                    <Col>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Algorithm 1</Card.Header>
                                <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_disentanglement === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : "data:image/png;base64, " + morphed_image_helena_disentanglement} width="250" height="250" />
                            </Card>
                        </Row>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Button variant="outline-primary morphingButton" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Algorithm 2</Card.Header>
                                <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_inpainting === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : "data:image/png;base64, " + morphed_image_helena_inpainting} width="250" height="250" />
                            </Card>
                        </Row>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Button variant="outline-primary morphingButton" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Algorithm 3</Card.Header>
                                <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_nuno === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : "data:image/png;base64, " + morphed_image_nuno} width="250" height="250" />
                            </Card>
                        </Row>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Button variant="outline-primary morphingButton" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                        </Row>
                    </Col>
                </Row>
            </LoadingOverlay>

            {fullscreenImage && (
                <div
                    className="fullscreen-overlay"
                    onClick={closeFullscreen}
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.9)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                        cursor: "pointer",
                    }}
                >
                    <img
                        src={fullscreenImage}
                        alt="Fullscreen View"
                        style={{
                            maxWidth: "90%",
                            maxHeight: "90%",
                            objectFit: "contain",
                        }}
                    />
                </div>
            )}

        </Container >

    )
}

export default withRouter(MorphingPage)