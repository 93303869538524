import React, { useContext, useEffect, useState, useRef } from 'react'

import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath, useLocation } from "react-router-dom";

import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/esm/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import LoadingOverlay from 'react-loading-overlay';
import { IconCheckbox } from "react-icon-checkbox";


const PatientPhotosPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [errors, setErrors] = useState({})
    const [active, setActive] = useState(false)

    const location = useLocation()

    let patient_id = location.state.patient_id
    let date = location.state.date
    let page_info = location.state.page !== undefined ? location.state.page : null
    let institutional_number = location.state.institutional_number
    let is_pre_surgery = location.state.pre_surgery

    const [appState, setAppState] = useState({
        isLoading: true,
        state: null,
        icon_state: []
    })

    let getPhotos = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/photographs/' + date, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            let aux = []
            data.patient_photos.forEach(photo => {
                if (photo.id === data.favorite) {
                    aux.push(true)
                } else {
                    aux.push(false)
                }

            })
            setAppState({
                isLoading: false,
                state: data,
                icon_state: aux,
            })

        }
    }

    const handleChangeViewType = async (e, photo) => {
        photo.view_type = e.target.value
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + date + '/photos/' + photo.id + '/update/viewType', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: e.target.value,
        })
        let data = await response
    };

    const handleChangeImageType = async (e, photo) => {
        photo.img_type = e.target.value
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + date + '/photos/' + photo.id + '/update/imageType', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: e.target.value,
        })
        let data = await response
    };

    const updateFavoriteImage = async (index, patient_id, date, photo_id) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + date + '/photos/' + photo_id + '/update/favorite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
        })
        let data = await response
        if (response.status === 200) {
            let aux = appState.icon_state
            for (let i = 0; i < aux.length; i++) {
                if (i === index) {
                    if (aux[i] === true) {
                        aux[i] = false
                    }
                    else {
                        aux[i] = true
                    }
                }
                else {
                    aux[i] = false
                }
            }
            setAppState({
                isLoading: false,
                state: appState.state,
                icon_state: aux
            })
        }
    };

    const [show, setShow] = useState([false, null]);

    const handleClose = () => setShow([false, null]);
    const handleShow = (image) => setShow([true, image]);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    let deleteSingularImage = async (image) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + date + '/photo/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: image,
        })
        let data = await response
        if (response.status === 200) {
            getPhotos()
        }
        handleClose()
    }

    let deleteAllPhotos = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/photographs/' + date + '/delete/all', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
        })
        let data = await response
        if (response.status === 200) {
            getPhotos()
            handleClose2()
            history.push({ pathname: generatePath("/patient/:id", { id: patient_id }), state: { id: patient_id, page: page_info } })
        }

    }

    let predictKeyPoints = async (photo, date) => {
        if (photo.bra_value == '0') {
            setActive(true)
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + date + '/predict', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access,
                },
                body: photo.id,
            })
            let data = await response
            if (response.status === 200) {
                setActive(false)
                history.push({ pathname: generatePath("/patient/:id/:date/:photo_id/bcctcore", { id: patient_id, date: date, photo_id: photo.id }), state: { patient_id: patient_id, photo: photo, page: page_info, institutional_number: institutional_number, is_pre_surgery: is_pre_surgery, date: date} })
            }
        }
        else {
            history.push({ pathname: generatePath("/patient/:id/:date/:photo_id/bcctcore", { id: patient_id, date: date, photo_id: photo.id }), state: { patient_id: patient_id, photo: photo, page: page_info, institutional_number: institutional_number, is_pre_surgery: is_pre_surgery, date: date } })
        }

    }

    const [show3, setShow3] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleShow3 = (image) => {
        setSelectedImage(image);
        setShow3(true);
    };

    const handleClose3 = () => {
        setShow3(false);
        setSelectedImage(null);
    };

    useEffect(() => {
        getPhotos()
    }, [])

    if (!isAuthenticated) {
        history.push("/")
    }
    else {
        if (appState.isLoading === true) {
            return (
                <Container className='text-center'>
                    <div style={{ marginTop: '22.5%', }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </Container>
            )
        }
        else {
            return (

                <Container className='px-4 mb-4'>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: appState.state.patient[0].id }), state: {id: appState.state.patient[0].id} })}>
                            Patient {institutional_number} Profile
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Patient's {institutional_number} Photos
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <Row>
                        <Col>
                            <h2 className='mt-3' style={{ color: "#444444" }}>Patient: {appState.state.patient[0].institutional_number}</h2>
                            {
                                appState.state.pre_surgery ?
                                    <h4 className='mt-3' style={{ color: "#444444" }}>Pre-surgery Photos</h4> :
                                    appState.state.post_surgery ?
                                        <h4 className='mt-3' style={{ color: "#444444" }}>Post-surgery Photos</h4> :
                                        null
                            }
                        </Col>
                        <Col>
                            <div className='addPatient-div'>
                                <Button variant="outline-primary" className="addPatient-button my-auto" onClick={() => history.push({ pathname: generatePath("/patient/:id/upload", { id: location.state.patient_id }), state: { date: location.state.date, id: location.state.patient_id, institutional_number: location.state.institutional_number, date: date } })}><i className="fa fa-plus" aria-hidden="true"></i> Add Photo</Button>
                            </div>
                            <div className='addPatient-div'>
                                <Button variant="outline-danger" className="my-auto" onClick={handleShow2}><i className="fa fa-trash" aria-hidden="true"></i> Delete All Photos</Button>

                                <Modal show={show2} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete All Images</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure you want to delete all images?
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose2}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={() => deleteAllPhotos()}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </Col>
                    </Row>
                    {
                        location.state.pre_surgery ? null :
                            location.state.fup_years && location.state.fup_months ?
                                <h4 className='mt-3' style={{ color: "#444444" }}>Follow-up time: {location.state.fup_years} years, {location.state.fup_months} months</h4> :
                                location.state.fup_years && !location.state.fup_months ?
                                    <h4 className='mt-3' style={{ color: "#444444" }}>Follow-up time: {location.state.fup_years} years</h4> :
                                    !location.state.fup_years && location.state.fup_months ?
                                        <h4 className='mt-3' style={{ color: "#444444" }}>Follow-up time: {location.state.fup_months} months</h4> :
                                        <h4 className='mt-3' style={{ color: "#444444" }}>Follow-up time: less than 1 month</h4>
                    }

                    <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>

                    <Container className=''>
                        <LoadingOverlay active={active} spinner text='Ploting keypoints...'>
                            <Row xs={1} md={3} className='g-2'>
                                {
                                    appState.state.patient_photos.map((photo, index) => {
                                        return (
                                            <Col key={photo.image}>

                                                <Card>
                                                    <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + photo.image} onClick={() => handleShow3('https://breloai-backend.inesctec.pt' + photo.image)} width="250" height="250" />
                                                    <Card.ImgOverlay className='addPatient-div' style={{ fontSize: "1.8rem", height: '2vh' }}>
                                                        <IconCheckbox checked={appState.icon_state[index]}
                                                            checkedIcon={<i className="fa-solid fa-heart" style={{ color: 'red' }} aria-hidden="true"></i>}
                                                            uncheckedIcon={<i className="fa-regular fa-heart" style={{ color: 'red' }} aria-hidden="true"></i>}
                                                            onClick={() => updateFavoriteImage(index, patient_id, date, photo.id)}
                                                        />
                                                    </Card.ImgOverlay>
                                                    <Card.Body>
                                                        <div className='my-3'>
                                                            <Button variant="outline-danger" className='OutlineDangerButton' style={{ width: '100%', }} onClick={() => handleShow(photo.image)}>Delete</Button>

                                                            <Modal show={show[0]} onHide={handleClose}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Delete Image</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    Are you sure you want to delete this image?
                                                                    <Container className='text-center'>
                                                                        <Image src={'https://breloai-backend.inesctec.pt' + show[1]} width="200" height={200}></Image>
                                                                    </Container>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="secondary" onClick={handleClose}>
                                                                        Cancel
                                                                    </Button>
                                                                    <Button variant="danger" onClick={() => deleteSingularImage(show[1])}>
                                                                        Confirm
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </div>

                                                        <Row xs={1} md={2} className="justify-content-center mb-3">
                                                            <Button disabled={!appState.icon_state[index]} className='btn-sm FilledButton mx-1' onClick={() => predictKeyPoints(photo, photo.date_created)}>Plot</Button>
                                                            <Button disabled={!appState.icon_state[index]} className='btn-sm FilledButton mx-1' onClick={() => history.push({ pathname: generatePath("/image/:image_id/interactions", { id: patient_id, date: date, image_id: photo.id }), state: { patient_id: patient_id, photo: photo } })}>Interactions</Button>
                                                            <Button disabled={!appState.icon_state[index] || photo.view_type != 1} className='btn-sm FilledButton mx-1' onClick={() => history.push({ pathname: generatePath("/patient/:id/:date/:photo_id/nipple", { id: patient_id, date: date, photo_id: photo.id }), state: { patient_id: patient_id, photo: photo, page: page_info, institutional_number: institutional_number, date: date } })}>Nipple</Button>
                                                        </Row>

                                                        <div>
                                                            <Form.Group as={Row} className='mb-2'>
                                                                <Form.Label column sm={4}>View Type</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Select name="view_type" defaultValue={photo.view_type} onChange={(e) => handleChangeViewType(e, photo)}>
                                                                        <option value="0">---------</option>
                                                                        <option value="1">Anterior</option>
                                                                        <option value="2">Posterior</option>
                                                                        <option value="3">Lateral Left</option>
                                                                        <option value="4">Lateral Right</option>
                                                                        <option value="5">Left 45 Degrees</option>
                                                                        <option value="6">Right 45 Degrees</option>
                                                                    </Form.Select>
                                                                </Col>

                                                            </Form.Group>
                                                            <Form.Group as={Row} className='mb-1'>
                                                                <Form.Label column sm={4}>Image Type</Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Select name="image_type" defaultValue={photo.img_type} onChange={(e) => handleChangeImageType(e, photo)}>
                                                                        <option value="0">---------</option>
                                                                        <option value="1">Aesthetic Assessment</option>
                                                                        <option value="2">Mammography</option>
                                                                    </Form.Select>
                                                                </Col>
                                                            </Form.Group>
                                                        </div>

                                                    </Card.Body>
                                                </Card>

                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </LoadingOverlay>
                    </Container>

                    {/* Bootstrap Modal for overlay */}
                    <Modal show={show3} onHide={handleClose3} centered size="lg">
                        <Modal.Body style={{ maxWidth: "100%", maxHeight: "80vh"}} className="d-flex justify-content-center align-items-center">
                            <img src={selectedImage} alt="Selected" className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={handleClose3} className="btn btn-secondary">Close</button>
                        </Modal.Footer>
                    </Modal>

                </Container >
            )
        }
    }


}

export default withRouter(PatientPhotosPage)