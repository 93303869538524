import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import AuthContext from '../context/AuthContext'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withRouter } from "react-router-dom";
import history from '../history';
import { useLocation, generatePath } from "react-router-dom";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const LoginPage = () => {
  let { loginUser, url } = useContext(AuthContext)
  const [errors, setErrors] = useState({})

  const [email, setEmail] = useState(null)
  const location = useLocation()

  let resetPassword = async (email) => {
    let response = await fetch('https://breloai-backend.inesctec.pt/api/resetPassword/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'email': email })
    })
    if (response.status === 200) {
      setErrors({
        'email': "Check your email for further instructions!",
      })
    }
  }

  let logIn = async (e) => {
    e.preventDefault()
    let result = await loginUser(e)
    if (result === true) {
      if (location.state !== undefined) {
        if (location.state.patient_id != null && location.state.photo_date != null && location.state.photo_id != null) {
          history.push("/patient/" + location.state.patient_id + "/" + location.state.photo_date + "/" + location.state.photo_id + "/cankado/bcctcore")
        }
        else {
          history.push("/")
        }
      }
      else {
        history.push("/")
      }
    } else {
      setErrors({
        'password': "Password does not match the username!",
      })
    }
  }

  return (
    <div className='login-form-container'>
      <span>Log In</span>
      <Form className='loginForm my-3' onSubmit={logIn}>
        <Form.Group className="authenticate mx-5 my-4 height-auto">
          <Form.Label>Username</Form.Label>
          <Form.Control name="username" type="text" placeholder="Enter your username" isInvalid={!!errors.username} />
          <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" name="password" placeholder="Enter your password" isInvalid={!!errors.password} />
          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          <Button className="authenticate-Button" variant="primary" type="submit">
            Submit
          </Button>
          <OverlayTrigger
            trigger="click"
            key='bottom'
            placement='bottom'
            overlay={
              <Popover id='popover-positioned-bottom'>
                <Popover.Body>
                  Provide the email address of the account to reset the password:
                  <Form.Control className="my-2" name="email" type="text" placeholder="Enter the email" onChange={(e) => setEmail(e.target.value)} isValid={!!errors.email} />
                  <Form.Control.Feedback type="valid">{errors.email}</Form.Control.Feedback>
                  <Button variant="success" onClick={() => resetPassword(email)}>Reset Password</Button>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="link">Forgot Password?</Button>
          </OverlayTrigger>
        </Form.Group>
        <div className='registerLinks mr-3 my-auto'>
          <p>Create a Personal User Account <a onClick={() => history.push("/register")}>Here</a></p>
          <p>Create an Institutinal User Account <a onClick={() => history.push("/teamRegister")}>Here</a></p>
        </div>
      </Form>
      {/*<a className='webbcct-standalone-link' onClick={() => { history.push("/webbcctcore")}}>Access the standalone version of the WebBCCT.Core Software</a>*/}
    </div>
  )
}

export default withRouter(LoginPage)
