import { withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaCamera } from 'react-icons/fa';
import history from '../history';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { generatePath } from 'react-router-dom';


import { Pose, POSE_CONNECTIONS } from '@mediapipe/pose';
import { Camera } from '@mediapipe/camera_utils';

const CameraPage = () => {
    const [positionType, setPositionType] = useState("Frontal");
    const [capturedImage, setCapturedImage] = useState(null); 

    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const cameraRef = useRef(null);
    const isComponentMounted = useRef(true);
    const frameCountRef = useRef(0);

    const frameThreshold = 15; // Number of frames to detect the position for certain number of seconds


    const calculateAngle = (a, b, c) => {
        const radians = Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x);
        let angle = Math.abs((radians * 180.0) / Math.PI);
        if (angle > 180.0) {
            angle = 360 - angle;
        }
        return angle;
    };

    // Function to check if the person is in the correct position
    const checkPosition = useCallback((landmarks, position) => {
        const isArmHierarchyValid = (shoulder, elbow, wrist) => {
            return shoulder.y < elbow.y && elbow.y < wrist.y;
        };
        
        if (position === "Frontal") {
            if (
                !legsVisible(landmarks) &&
                leftArmVisible(landmarks) &&
                rightArmVisible(landmarks)
            ) {
                const leftArmAngle = calculateAngle(
                    landmarks[11], // LEFT_SHOULDER
                    landmarks[13], // LEFT_ELBOW
                    landmarks[15]  // LEFT_WRIST
                );
    
                const rightArmAngle = calculateAngle(
                    landmarks[12], // RIGHT_SHOULDER
                    landmarks[14], // RIGHT_ELBOW
                    landmarks[16]  // RIGHT_WRIST
                );
    
                console.log(`Frontal Position - Left Arm Angle: ${leftArmAngle}, Right Arm Angle: ${rightArmAngle}`);
    
                return (
                    leftArmAngle > 85 &&
                    leftArmAngle < 140 &&
                    rightArmAngle > 85 &&
                    rightArmAngle < 140 &&
                    isArmHierarchyValid(landmarks[11], landmarks[13], landmarks[15]) &&
                    isArmHierarchyValid(landmarks[12], landmarks[14], landmarks[16])
                );
            }
        } else if (position === "Lateral Right") {
            if (!legsVisible(landmarks) && rightArmVisible(landmarks) && !leftArmVisible(landmarks)) {
                const rightArmAngle = calculateAngle(
                    landmarks[12], // RIGHT_SHOULDER
                    landmarks[14], // RIGHT_ELBOW
                    landmarks[16]  // RIGHT_WRIST
                );
    
                console.log(`Lateral Right Position - Right Arm Angle: ${rightArmAngle}`);
                console.log(rightArmAngle > 85 &&
                    rightArmAngle < 140 &&
                    isArmHierarchyValid(landmarks[12], landmarks[14], landmarks[16]));
                return (
                    rightArmAngle > 85 &&
                    rightArmAngle < 140 &&
                    isArmHierarchyValid(landmarks[12], landmarks[14], landmarks[16])
                );
            }
        } else if (position === "Lateral Left") {
            if (!legsVisible(landmarks) && leftArmVisible(landmarks) && !rightArmVisible(landmarks)) {
                const leftArmAngle = calculateAngle(
                    landmarks[11], // LEFT_SHOULDER
                    landmarks[13], // LEFT_ELBOW
                    landmarks[15]  // LEFT_WRIST
                );
    
                console.log(`Lateral Left Position - Left Arm Angle: ${leftArmAngle}`);
    
                return (
                    leftArmAngle > 85 &&
                    leftArmAngle < 140 &&
                    isArmHierarchyValid(landmarks[11], landmarks[13], landmarks[15])
                );
            }
        }
        return false;
    }, []);
    

    const legsVisible = (landmarks) => {
        return (
            landmarks[25].visibility > 0.4 && landmarks[27].visibility > 0.4 &&
            landmarks[26].visibility > 0.4 && landmarks[28].visibility > 0.4
        );
    };

    const leftArmVisible = (landmarks) => {
        return (
            landmarks[11].visibility > 0.4 && landmarks[13].visibility > 0.4 && landmarks[15].visibility > 0.4
        );
    };

    const rightArmVisible = (landmarks) => {
        return (
            landmarks[12].visibility > 0.4 && landmarks[14].visibility > 0.4 && landmarks[16].visibility > 0.4
        );
    };

    useEffect(() => {
        if (capturedImage) return; // If an image is captured, stop camera processing

        isComponentMounted.current = true;

        const videoElement = videoRef.current;

        const onResults = (results) => {
            if (!isComponentMounted.current || !canvasRef.current) return;

            const canvasElement = canvasRef.current;
            const canvasCtx = canvasElement.getContext('2d');

            canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

            // Draw landmarks if available and visible
            if (results.poseLandmarks) {
                POSE_CONNECTIONS.forEach(([startIdx, endIdx]) => {
                    const start = results.poseLandmarks[startIdx];
                    const end = results.poseLandmarks[endIdx];

                    if (start.visibility > 0.5 && end.visibility > 0.5) {
                        canvasCtx.beginPath();
                        canvasCtx.moveTo(start.x * canvasElement.width, start.y * canvasElement.height);
                        canvasCtx.lineTo(end.x * canvasElement.width, end.y * canvasElement.height);
                        canvasCtx.strokeStyle = "#00FF00";
                        canvasCtx.lineWidth = 4;
                        canvasCtx.stroke();
                    }
                });

                // Draw landmarks
                results.poseLandmarks.forEach((landmark) => {
                    if (landmark.visibility > 0.5) {
                        canvasCtx.beginPath();
                        canvasCtx.arc(
                            landmark.x * canvasElement.width,
                            landmark.y * canvasElement.height,
                            5,
                            0,
                            2 * Math.PI
                        );
                        canvasCtx.fillStyle = "#FF0000";
                        canvasCtx.fill();
                    }
                });

                // Check the selected position and update frame count
                if (checkPosition(results.poseLandmarks, positionType)) {
                    frameCountRef.current += 1;
                    console.log(`Position detected. Frame count: ${frameCountRef.current}`);
                }
                

                // Automatically capture a photo if the position is held 
                if (frameCountRef.current >= frameThreshold) {
                    console.log("Photo captured!");
                    capturePhoto();
                    frameCountRef.current = 0;
                }
            }
        };

        try {
            const pose = new Pose({
                locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.5/${file}`,
            });

            pose.setOptions({
                modelComplexity: 1,
                smoothLandmarks: true,
                enableSegmentation: false,
                minDetectionConfidence: 0.6,
                minTrackingConfidence: 0.6,
            });

            pose.onResults(onResults);

            if (videoElement) {
                cameraRef.current = new Camera(videoElement, {
                    onFrame: async () => {
                        if (isComponentMounted.current) {
                            await pose.send({ image: videoElement });
                        }
                    },
                    width: 960,
                    height: 540,
                });
                cameraRef.current.start();
            }
        } catch (error) {
            console.error("Error initializing Mediapipe Pose: ", error);
        }

        // Cleanup function to stop the camera when image is captured
        return () => {
            isComponentMounted.current = false;

            if (cameraRef.current) {
                cameraRef.current.stop();
            }

            if (videoElement && videoElement.srcObject) {
                videoElement.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        };
    }, [positionType, checkPosition, capturedImage]);

    // Function to manually capture photo
    const capturePhoto = () => {
        if (canvasRef.current) {
            const canvasElement = canvasRef.current;
            const dataUrl = canvasElement.toDataURL('image/png');
            setCapturedImage(dataUrl); // Store the captured image in the state
        }
    };

    // Function to retake the photo
    const retakePhoto = () => {
        setCapturedImage(null);
        frameCountRef.current = 0;
    };

    // Function to download the captured photo
    const downloadPhoto = () => {
        if (capturedImage) {
            const link = document.createElement('a');
            link.href = capturedImage;
            link.download = 'captured_image.png';
            link.click();
        }
    };

    return (
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Camera Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <span><h3>Camera</h3></span>
            
            <Row className="my-3">
                <Col xs={12} md={6}>
                    <Form.Group controlId="positionSelect">
                        <Form.Label>Select Position Type</Form.Label>
                        <Form.Select
                            value={positionType}
                            onChange={(e) => setPositionType(e.target.value)}
                            disabled={capturedImage !== null} // Disable when photo is captured
                        >
                            <option value="Frontal">Frontal</option>
                            <option value="Lateral Right">Lateral Right</option>
                            <option value="Lateral Left">Lateral Left</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ position: 'relative', margin: '0 auto', width: '60%', height: 'auto' }}>
                        {capturedImage ? (
                            <img
                                src={capturedImage}
                                alt="Captured"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : (
                            <>
                                <video ref={videoRef} style={{ display: 'none' }} />
                                <canvas
                                    ref={canvasRef}
                                    width="1280"
                                    height="720"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                    }}
                                />
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="my-3 d-flex justify-content-center">
                <Col className="text-center">
                    {capturedImage ? (
                        <>
                            <Button onClick={retakePhoto} variant="warning" className="me-2">
                                Retake
                            </Button>
                            <Button onClick={downloadPhoto} variant="success">
                                Download
                            </Button>
                        </>
                    ) : (
                        <Button onClick={capturePhoto} variant="primary" className="my-3">
                            <FaCamera size={24} />
                        </Button>
                    )}
                </Col>
            </Row>

        </Container>
    );
};

export default withRouter(CameraPage);
